import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { CartService } from '../../services/cart.service';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-top20',
  templateUrl: './top20.component.html',
  styleUrls: ['./top20.component.scss']
})
export class Top20Component implements OnInit, OnDestroy {

  @ViewChild('modalImageBrowser', { static: true }) modalImageBrowser?: NgbModal;
  @ViewChild('lf', {static: true}) myForm: NgForm;
  items: any[];
  celebItems: any[];
  embroideredItems: any[];
  private unsubscribe$ = new Subject<void>();
  private unsubscribe2$ = new Subject<void>();
  private unsubscribe3$ = new Subject<void>();
  showSpinner: boolean;
  showSpinner2: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  celebrationCommitteeChairEmail = 'dan@dogfishsoftware.com';
  celebrationCommitteeChairName = 'Dan Zimmerman'
  embroideryCommitteeChairEmail = 'dan@dogfishsoftware.com';
  embroideryCommitteeChairName = 'Dan Zimmerman';
  deadline = '';
  deadline2 = '';
  deadline3 = '';
  deadlineTime = 0;
  deadlineTime2 = 0;
  deadlineTime3 = 0;
  productNotAvailable = true;
  productNotAvailable2 = true;
  productNotAvailable3 = true;
  noProductsAvailable = false;
  noProductsAvailable2 = false;
  noProductsAvailable3 = false;
  soldOut = false;
  soldOut2 = false;
  soldOut3 = false;
  comingSoon = false;
  comingSoon2 = false;
  comingSoon3 = false;
  availabilityDate = '';
  availabilityDate2 = '';
  availabilityDate3 = '';
  availabilityDateTime = 0;
  availabilityDateTime2 = 0;
  availabilityDateTime3 = 0;
  images = [];
  images2 = [];
  images3 = [];
  formDisabled: boolean;
  topdogForm: FormGroup;
  intId = -1;

  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ]
  };

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private activeModal: NgbActiveModal,
              private ngZone: NgZone,
              private fb: FormBuilder,
              private api: ApiService,
              public store: Store<CustomerState>,
              private cartService: CartService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.topdogForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]]
    });
    this.formDisabled = true;
    this.showSpinner = true;
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=3&variants=true&orderBy=baseSkuNumber')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.items = res.products;
                      const prod = res.products[0];
                      this.showSpinner = false;
                      const availabilityMap = this.cartService.productIsAvailable(prod);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                    } else {
                      // no products to show:
                      this.showSpinner = false;
                      this.noProductsAvailable = true;
                    }
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of Top 20 merchandise.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
          this.showSpinner2 = true;

          this.api.get('/products?category=34&variants=true&orderBy=baseSkuNumber')
            .pipe(takeUntil(this.unsubscribe2$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.celebItems = res.products;
                      const prod = res.products[0];
                      this.showSpinner2 = false;
                      const availabilityMap = this.cartService.productIsAvailable(prod);
                      this.productNotAvailable2 = availabilityMap.productNotAvailable;
                      this.availabilityDate2 = availabilityMap.availabilityDate;
                      this.availabilityDateTime2 = availabilityMap.availabilityDateTime;
                      this.deadlineTime2 = availabilityMap.deadlineTime;
                      this.deadline2 = availabilityMap.deadline;
                      this.comingSoon2 = availabilityMap.comingSoon;
                      this.soldOut2 = availabilityMap.soldOut;
                    } else {
                      // no products to show:
                      this.showSpinner2 = false;
                      this.noProductsAvailable2 = true;
                    }
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner2 = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of Top 20 celebration items.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
          this.api.get('/products?category=49&variants=true&orderBy=baseSkuNumber')
            .pipe(takeUntil(this.unsubscribe3$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.embroideredItems = res.products;
                      const prod = res.products[0];
                      const availabilityMap = this.cartService.productIsAvailable(prod);
                      this.productNotAvailable3 = availabilityMap.productNotAvailable;
                      this.availabilityDate3 = availabilityMap.availabilityDate;
                      this.availabilityDateTime3 = availabilityMap.availabilityDateTime;
                      this.deadlineTime3 = availabilityMap.deadlineTime;
                      this.deadline3 = availabilityMap.deadline;
                      this.comingSoon3 = availabilityMap.comingSoon;
                      this.soldOut3 = availabilityMap.soldOut;
                    } else {
                      // no products to show:
                      this.noProductsAvailable3 = true;
                    }
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of Top 20 embroidery items.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/42')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });

    this.api.get('/committees/43')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.celebrationCommitteeChairEmail = res.contactEmail;
              this.celebrationCommitteeChairName = res.contactName;
            }
          })
        });
    this.api.get('/committees/66')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.embroideryCommitteeChairEmail = res.contactEmail;
              this.embroideryCommitteeChairName = res.contactName;
            }
          })
        });
  }

  sendForm() {
    if (this.topdogForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 45,
        formData: {
          fullName: this.topdogForm.controls.name.value,
          emailAddress: this.topdogForm.controls.emailAddress.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm.resetForm();
      this.topdogForm.reset({
        name: '',
        emailAddress: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Top Dog Celebration coordinator.' +
        '  Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe2$.next();
    this.unsubscribe2$.complete();
    this.unsubscribe3$.next();
    this.unsubscribe3$.complete();
  }
  openImageBrowser(variantImageArray) {
    this.images = variantImageArray.map((variantImage) => variantImage.path);
    this.modalService.open(this.modalImageBrowser, { windowClass: 'modal-preview', centered: true });
  }

  trackByIndexItem(index, item): any {
    return index;
  }

  downloadForm() {
  }
}
